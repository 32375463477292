import { Popup, Marker } from 'react-leaflet'

const data = require("./data/bases.json"); // should also now work for geojson

export function Bases() {
  return (
    <div>
        {data.map((base: any) => (
          <Marker
          position={[base.geometry.coordinates[1],base.geometry.coordinates[0]]}
          key={data.indexOf(base)}
          >
            <Popup>
              <b>{base.properties.name}</b> <br /> {base.properties.description}
            </Popup>
          </Marker>
        ))}
    </div>
  );
}
