import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArticleIcon from '@mui/icons-material/Article';
import Tooltip from '@mui/material/Tooltip';

export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1, margin: 0 }}>
        <AppBar position="static"> {/*  Static puts border around appbar, but if no static, cannot view other elements*/}
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Military Base Location Map
                </Typography>
                <Tooltip title="About">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                    <ArticleIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
    </Box>
  );
}