import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';
import { sizing } from '@mui/system';
import { MapContainer, TileLayer, useMap, Popup, Marker } from 'react-leaflet';
import { Bases } from './Bases';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Stack from '@mui/material/Stack';

export default function DisplayMap() {
    function VisitRandom() {
      const map = useMap();
      const button1 = document.getElementById('B_visitRandom');
        button1?.addEventListener('click', () => {
        const data = require("./data/bases.json");
        const base = data[Math.floor(Math.random() * data.length)];
        map.setView([base.geometry.coordinates[1],base.geometry.coordinates[0]], 15);
      });
      return null;
    }
    return (
      <Box sx={{ flexGrow: 1, margin: 0}} >
          <Stack direction="row" spacing={1} marginTop={1} marginBottom={1}>
            <Button id='B_visitRandom' variant="outlined" startIcon={<LocationOnIcon />} >
                VIST RANDOM
            </Button>
          </Stack>
          <div>
            <MapContainer style={{height: 1000 }} center={[30, 0]} zoom={4} scrollWheelZoom={true} >
              <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Bases/>
              <VisitRandom />
          </MapContainer>
          </div>
          
          <Typography>Base location have been taken from the <a href="https://sites.google.com/view/osintmilitarymap/home" target="blank">OSINT Military Base Map Project</a> under a Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License.</Typography>
      </Box>
  );
}