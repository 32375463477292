import ButtonAppBar from './Appbar';
//import ViewRandom from './Controls';
import DisplayMap from './Map';

const App = () => {
    console.log("SERVER STARTED");
    return (
        <div>
            <ButtonAppBar/>
            <DisplayMap/>
        </div>
    );
}

export default App;
